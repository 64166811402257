.show-only-time .non-nullable {
    font-size: 18px;
}

.show-only-time .non-nullable.open {
    background-color: rgba(80,156,4,0.55);
}

.show-only-time .nullable {
    font-size: 18px;
}

.input-selected {
    border: 2px solid #a41f18;
    border-radius: 15px;
    margin: 4px 2px;
    padding: 2px 8px;
    font-size: 18px;
}

.flex-buttons-space {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.my-custom-modal  {
    --width: 300px;
    --height: 270px;
}


ion-datetime {
    --background: rgb(245, 235, 247);
    --background-rgb: 245, 235, 247;
    --wheel-highlight-background: rgb(218, 216, 255);
    --wheel-fade-background-rgb: 245, 235, 247;
}

ion-datetime::part(wheel-item) {
    color: rgb(255, 66, 97);
}

ion-datetime::part(wheel-item active) {
    color: rgb(128, 30, 171);
}