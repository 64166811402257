header {

}

.choose-button {
    background-color: #0ec254;
    padding: 15px;
    font-size: 1.3em;
    text-align: center;
}

.big-button {
    border: 1px solid #3880ff;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12);
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
}

.big-button h2 {
    margin: 10px;
}

h2.date {
    margin: 0;
    padding: 0;
}
.date .btn {
    --background: rgba(0, 0, 0, 0.12)
}

.big-button:hover {
    background-color: #BCCDFF
}

.text-center {
    text-align: center;
}

.fab-menu {
    background: #3880ff;
    border-radius: 30px;
    left: -20px;
    top: -13px;
    width: 40px;
    height: 40px;
    z-index: 50;
    color: white;

}
.fab-menu ion-icon {
    position: absolute;
    left: 7px;
}