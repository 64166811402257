.logbook-table {
    min-width: 800px;
}

.logbook-table tr,
.logbook-table tr td,
.logbook-table tr th {
    border: 1px solid #1e2023;
}

.logbook-table tr {
    border: 2px solid #1e2023;
}

.logbook-table tr th {
    padding: 0 5px;
    font-weight: 500;
    border: 2px solid #1e2023;
}

.logbook-table tr td {
    text-align: center;
    padding: 10px 0;
}

.logbook-table.partly-hidden td.hide-part,
.logbook-table.partly-hidden th.hide-part
{
    display: none;
}
.logbook-table tr td.hide,
.logbook-table tr th.hide {
    display: none;
}

.logbook-table tr td.hide-off,
.logbook-table tr th.hide-off {
    display: none;
}

.logbook-table tr td.show-column,
.logbook-table tr th.show-column {
    padding: 0 0;
    width: 10px;
}

.side-space {
    padding: 0 8px;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
}

.zoom-pane {
    width: 100%;
    height: 100%;
}

.zoom-pane .scroll {
    min-height: 100%;
    /* Vertical align */
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
