.login-container {
    max-width: 420px;
    margin: 0 auto;
    margin-top: 40px;
}

.login-form-button {
    width: 100%;
}

ion-content.login-background {
    --background: #fff url('c.jpg') no-repeat center center / cover;
}

.submit-enter {
    position: absolute;
    visibility: hidden;
    left: -1000px;
}

.content-right {
    float: right;
}

.mt-100 {
    margin-top: 100px;
}

