.aerotow-table {
    width: 100%;
    border: 2px solid #1e2023;
    margin-bottom: 2px;
}
.aerotow-table tr.odd {
    background-color: #c9dfff;
}

.aerotow-table tr.even {
    background-color: #ffe2a4;
}

.aerotow-table tr.mark-error {
    background-color: #e9813f;
}


.aerotow-table tr td {
    border: 1px solid #979a9d;
    text-align: center;
}

.aerotow-table.suggest {
    width: 66%;
    opacity: 0.44;
}

ion-datetime {
    padding: 0;
}

.relative {
    position: relative;
}

.relative .edit-icon {
    position: absolute;
    top: 5%;
    right: 5%;
}

.edit-tow {
    /*z-index: 1;*/
    /*position: absolute;*/
    /*top: 5px;*/
    /*left: 5px;*/
    opacity: 0.8;
    margin: 0;
}

.show-only-time {
    margin: 0;
}

.type-count {
    line-height: 1.1em;
    font-size: 1.8em;
    position: relative;
    color: rgba(1,1,1,0.8);
    z-index: 0;
}


.type-copy {
    cursor: pointer;
}

.type-count:hover {
    color: rgba(56, 132, 255, 0.80);
}

.type-copy .copy-icon {
    color: rgba(56, 132, 255, 0.80);
}



.start-time {
    font-size: 18px;
    font-weight: bold;
}

td.start-time {
    width: 20%;
}

td.plane-tow {
    width: 25%;
}

td.plane-glider {
    width: 20%;
}

td.pilot-tow {
    width: 20%;
}

td.pilot-glider {
    width: 20%;
}

td.tow-landing {
    width: 20%;
}

td.glider-landing {
    width: 20%;
}



.plane-glider {
    font-size: 1em;
}

.plane-glider small {
    font-size: 1.2em;
    font-weight: bold;
}

.plane-tow {
    font-size: 1em;
}

.plane-tow small {
    font-size: 1.2em;
    font-weight: bold;
}

.glider-landing {
    padding: 5px 0;
    font-size: 18px;
}

.tow-landing {
    padding: 5px 0;
    font-size: 18px;
}

.font-l {
    font-size: 18px;
}

.blinking {
    color: #a60300;
}

@keyframes blinkingText{
    50% {
        opacity: 0;
    }
}
