.aprs-table, .aprs-table tr, .aprs-table td, .aprs-table th {
    border: 1px solid black;
    border-collapse: collapse;
}

.aprs-table td, .aprs-table th {
    padding: 0 5px;
}
@media screen and (max-width: 640px) {
    .grid-small {
        font-size: 0.75rem;
    }
    .aprs-table {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 640px) {
    .no-mobile {
        display: none;
    }
}

.custom-grid {
    border: 1px solid #000;
}

.custom-grid ion-row {
    border-bottom: 1px solid #000;
}

.custom-grid .grid-header {
    font-weight: bold;
}

.filter-link {
    color: #3880ff;
    cursor: pointer;
}