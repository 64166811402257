.popover-content-custom-class .popover-content {
    padding-left: 20px;
    padding-right: 20px;

    display: block;
    width: auto;
    position: static;
}


