.flex-columns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-basis: 50px;
    padding: 4px 0;
}
.flex-columns.justify-end {
    justify-content: flex-end;
    flex-grow: 2;
}

.flex-rows {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.semaphore {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 30px;

}

@media screen and (max-width: 640px) {
    .semaphore {
        margin-right: 10px;
    }
}



.glider-info {
    display: flex;
    flex-direction: row;
}

.semaphore.not-available {
    background-color: #8e8d93;
}

.semaphore.warn {
    background-color: #a99020;
}

.semaphore.bad {
    background-color: #932828;
}

.semaphore.ok {
    background-color: #628d0f;
}

.hours-minutes {
    padding-right: 20px;
    font-size: 0.75em;
}

@media screen and (max-width: 640px) {
    .hours-minutes {
        padding-right: 10px;
    }
    .sc-ion-buttons-md-h.sc-ion-buttons-md-s {
        margin: 0;
    }
}

